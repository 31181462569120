import { Models } from 'models/core';
import { autoinject } from 'aurelia-framework';
import { AuthHttpClient } from 'services/auth-http-client';
import { BaseApiService } from 'services/base-api-service';
import {
  InvoiceableService,
  ServiceInvoiceDetails,
  ServiceInvoiceLinesUpdateDto,
  ServiceInvoiced,
  ServiceToInvoice,
} from 'models';
import { DataFilter } from 'lib/tables/DataFilter';
import { saveAs } from 'file-saver';
import { DateFormatValueConverter } from 'value-converters/date-format';

@autoinject
export class ServiceInvoiceService extends BaseApiService<Models.Service> {
  constructor(httpClient: AuthHttpClient) {
    super(httpClient, Models.Service);
  }

  public async invoice(data: ServiceToInvoice[]): Promise<number[]> {
    return await this.httpClient.exec('api/serviceInvoices', {
      method: 'POST',
      body: JSON.stringify(data),
    });
  }

  public async getInvoiceLinesByInvoiceIds(data: number[]): Promise<ServiceInvoiceDetails[]> {
    const params = new URLSearchParams();
    data.forEach((id) => params.append('invoiceIds', id.toString()));
    return await this.httpClient.exec(`api/serviceInvoices/lines?${params.toString()}`, {
      method: 'GET',
    });
  }

  public async allInvoices(filters: DataFilter) {
    if (filters.export) {
      const f = await this.httpClient.execNoTransform(`api/serviceInvoices${filters.toQueryString()}`, {
        method: 'GET',
      });
      return f.blob().then((blob) => {
        const dateString = new DateFormatValueConverter().toView(new Date(), 'DD-MM-YYYY');
        saveAs(blob, `service-invoiced-report-${dateString}.xlsx`);
      });
    } else {
      return await this.httpClient.execWithResponseMeta<ServiceInvoiced[]>(
        `api/serviceInvoices${filters.toQueryString()}`,
        {
          method: 'GET',
        }
      );
    }
  }

  public async resetInvoice(id: string): Promise<void> {
    await this.httpClient.execNoTransform(`api/serviceInvoices/reset`, {
      method: 'POST',
      body: JSON.stringify([id]),
    });
  }

  public async invoiceable(filters: DataFilter) {
    if (filters.export) {
      const f = await this.httpClient.execNoTransform(`api/serviceInvoices/invoiceable${filters.toQueryString()}`, {
        method: 'GET',
      });
      return f.blob().then((blob) => {
        const dateString = new DateFormatValueConverter().toView(new Date(), 'DD-MM-YYYY');
        saveAs(blob, `service-invoiceable-report-${dateString}.xlsx`);
      });
    } else {
      return await this.httpClient.execWithResponseMeta<InvoiceableService[]>(
        `api/serviceInvoices/invoiceable${filters.toQueryString()}`,
        {
          method: 'GET',
        }
      );
    }
  }

  public async generateInvoiceFiles(data: ServiceInvoiceLinesUpdateDto[]): Promise<number[]> {
    return await this.httpClient.exec(`api/serviceInvoices/generate-files`, {
      method: 'POST',
      body: JSON.stringify(data),
    });
  }

  public async update(data: ServiceInvoiceLinesUpdateDto[], id: number){
    return await this.httpClient.exec(`api/serviceInvoices/${id}/update`, {
      method: 'PATCH',
      body: JSON.stringify(data),
    });
  }

  public async approve(id: number) {
    return await this.httpClient.execNoTransform(`api/serviceInvoices/${id}/approve`, {
      method: 'POST',
    })
  }
}
